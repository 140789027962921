module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal-with-new-react/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AROS IT Consulting","short_name":"AROS IT","start_url":"/","background_color":"#fff","theme_color":"#02aab0","display":"standalone","icon":"src/images/aroslogo.svg","icons":[{"src":"src/images/favicon.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7893aa8ada207d3e85bcaeaa844ebc61"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
